<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-botoom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Detalle del Pago</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-card-title>
            <strong
              >Pago de Documento No:
              {{ data.code }}
            </strong>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-card height="100%">
                  <v-card-title>
                    Datos del pago
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" fab max @click="getPdf()" color="indigo">
                      <v-icon dark color="white"> mdi-printer</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="text--primary">
                    <p>
                      <strong>Empresa:</strong>
                      <span v-if="data.company == undefined"></span>
                      <span v-else>
                        {{ data.company.name }}
                      </span>
                    </p>
                    <p>
                      <strong>Proveedor:</strong>
                      <span v-if="data.provider == null"></span>
                      <span v-else>
                        {{ data.provider.name }}
                      </span>
                    </p>
                    <p>
                      <strong>Fecha:</strong>
                      {{ data.created | date }}
                    </p>
                    <p>
                      <strong>Estado: </strong>
                      <span v-if="data.is_confirmed">Pagado</span>
                      <span v-else>En proceso de pago</span>
                    </p>
                    <p>
                      <strong>Creado por:</strong>
                      {{ data.created_by.username }}
                    </p>
                    <p>
                      <strong>Total:</strong>
                      {{ data.amount }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-card height="100%">
                  <v-card-title> Datos del banco </v-card-title>
                  <v-card-text class="text--primary">
                    <p>
                      <strong>Forma de Pago:</strong>
                      <span v-if="data.payment_type == 'INVOICE'"> CONTADO/CŔEDITO </span>
                      <span v-else-if="data.payment_type == 'N/A'">SIN DOCUMENTO</span>
                      <span v-else-if="data.payment_type == 'NCRE'">NOTA DE CRÉDITO</span>
                      <span v-else-if="data.payment_type == 'CJCH'">CAJA CHICA</span>
                      <span v-else> CONTRAVALES </span>
                    </p>
                    <p>
                      <strong>Banco:</strong>
                      <span>
                        <span v-if="data.account != null">
                          {{ data.account.bank.name }} - {{ data.account.code }}
                        </span>
                        <v-icon
                          v-if="!data.is_confirmed && !data.floating_check"
                          medium
                          class="mr-2"
                          color="primary"
                          dark
                          @click="updateVal('Banco', data.id)"
                          >mdi-pencil</v-icon
                        >
                      </span>
                    </p>
                    <p>
                      <strong>Documento: </strong>
                      <span v-if="data.payment_type == 'NCRE'">{{ data.document }}</span>
                      <span v-else-if="data.is_check">Cheque </span>
                      <span
                        v-else-if="
                          !data.is_check &&
                          (data.document_code.length > 0) | (data.document != undefined)
                        "
                        >Transferencia
                      </span>
                      <span v-if="data.is_confirmed || data.floating_check">{{
                        data.document_code
                      }}</span>
                      <span v-else>
                        {{ data.document_code }}
                        <v-icon
                          medium
                          class="mr-2"
                          color="primary"
                          dark
                          @click="updateVal('document', data.id)"
                          >mdi-pencil</v-icon
                        >
                      </span>
                    </p>
                    <p v-if="data.is_check">
                      <strong>Fecha de emisión: </strong>
                      <span>{{ data.check_date }}</span>
                    </p>
                    <p>
                      <strong>Boleta:</strong>
                      <span v-if="data.is_confirmed || data.floating_check">
                        {{ data.deposit_slip }}</span
                      >
                      <span v-else>
                        {{ data.deposit_slip }}
                        <v-icon
                          medium
                          class="mr-2"
                          color="primary"
                          dark
                          @click="updateVal('Boleta', data.id)"
                        >
                          mdi-pencil</v-icon
                        >
                      </span>
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-title>
          <v-alert
            type="info"
            prominent
            v-if="!data.is_confirmed && !data.floating_check"
          >
            <v-row align="center">
              <v-col class="grow"> Actualizar información de pago o finalizar</v-col>
              <v-col class="shrink" v-if="!data.floating_check">
                <v-btn @click="markPayable()">Marcar como Cheque en Circulación</v-btn>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="updateVal('Fecha de Cobro', data.id)"
                  >Confirmar Pago</v-btn
                >
              </v-col>
            </v-row>
          </v-alert>
          <v-alert
            type="info"
            prominent
            v-if="!data.is_confirmed && data.floating_check"
          >
            <v-row align="center">
              <v-col class="grow"> Pago marcado como cheque en Circulación</v-col>
            </v-row>
          </v-alert>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="data.paymentdetail"
            hide-default-footer
            disable-pagination
            disable-sort
          >
            <template v-slot:[`item.document`]="{ item }">
              {{ item.document }}
            </template>
            <template v-slot:[`item.invoice`]="{ item }">
              <v-icon
                v-if="item.document_type != 'factura'"
                medium
                class="mr-2"
                color="primary"
                dark
                @click="selectInvoice(item.id)"
                >mdi-plus-circle</v-icon
              >
            </template>
          </v-data-table>
          <v-row v-if="!data.is_confirmed && !data.floating_check">
            <v-col class="text-end">
              <v-btn @click="selectInvoice(data.id, false)" color="primary"
                >Agregar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <update-field
      v-if="updateDialog"
      :title="title"
      :dialog="updateDialog"
      :label="label"
      :field="field"
      :business="data.company_id"
      @close="updateDialog = false"
      @saved="getPayment()"
      :uri="'api/accounting/' + data.id + '/'"
      :type="type"
    ></update-field>
    <select-invoice
      v-if="invoiceDialog"
      :dialog="invoiceDialog"
      :order="orderId"
      :provider="providerId"
      :paytype="credit"
      :business="business"
      :update="isUpdate"
      @close="invoiceDialog = false"
      @saved="getPayment()"
    ></select-invoice>
  </v-row>
</template>
<script>
import request from "@/services/requests";
import blobResponse from "@/utils/response.blob";
export default {
  props: {
    dialog: { type: Boolean, required: true },
    paymentId: { type: String, required: true },
  },
  components: {
    updateField: () => import("@/components/forms/UpdateField"),
    selectInvoice: () => import("@/components/accounting/SelectInvoice"),
  },
  data() {
    return {
      isUpdate: true,
      business: 0,
      type: "text",
      error: [],
      credit: undefined,
      providerId: 0,
      data: {
        company: {
          name: "",
        },
        provider: {
          provider: {
            name: "",
          },
        },
        created_by: {
          username: "",
        },
        account: {
          code: "",
          bank: {
            name: "",
          },
        },
        document_code: "",
      },
      updateDialog: false,
      invoiceDialog: false,
      label: "",
      title: "",
      field: "",
      detailId: 0,
      orderId: 0,
      headers: [
        { text: "Documento", value: "document" },
        { text: "Descripción", value: "description" },
        { text: "Factura", value: "invoice" },
        { text: "Total", value: "amount" },
      ],
    };
  },
  mounted() {
    this.getPayment();
  },
  methods: {
    getPayment() {
      request.get("api/accounting/" + this.paymentId + "/").then((res) => {
        this.data = res.data;
      });
    },
    updateVal(field, value) {
      if (field == "document") {
        this.field = "document_code";
        this.label = "Transferencia o Cheque";
        this.title = "Transferencia o Cheque";
        this.updateDialog = true;
        this.type = "text";
      } else if (field == "Boleta") {
        this.field = "deposit_slip";
        this.label = field;
        this.title = field;
        this.type = "text";
        this.updateDialog = true;
      } else if (field == "Fecha de Cobro") {
        this.field = "date";
        this.label = field;
        this.title = field;
        this.type = "date";
        this.updateDialog = true;
      } else if (field == "Banco") {
        this.field = "account_id";
        this.label = field;
        this.title = field;
        this.type = "text";
        this.updateDialog = true;
        this.business = this.data.company_id;
      }
      this.detailId = value;
    },
    selectInvoice(detail, withoutOrder = true) {
      this.isUpdate = withoutOrder;
      this.invoiceDialog = true;
      if (this.data.provider == null) {
        this.providerId = 0;
      } else {
        this.providerId = this.data.provider.id;
      }
      this.credit = this.data.payment_type;
      this.business = this.data.company_id;
      this.orderId = detail;
    },
    getPdf() {
      request.get("api/pdf/payment-document/" + this.paymentId).then((res) => {
        if (res.status == 200) {
          blobResponse(res.data, "application/pdf");
        } else {
          this.snackbarText = "Ha ocurrido un error";
          this.snackbarStatus = true;
        }
      });
    },
    close() {
      this.$emit("close");
      this.$emit("refresh");
      this.data = {
        company: {
          name: "",
        },
        provider: {
          provider: {
            name: "",
          },
        },
        created_by: {
          username: "",
        },
        account: {
          code: "",
          bank: {
            name: "",
          },
        },
      };
    },
    markPayable() {
      if (this.data.is_check) {
        request
          .patch("/api/accounting/" + this.data.id + "/", {
            payable: true,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$toasted.global.info({
                message: "Operación realizada con éxito.",
              });
              this.getPayment();
            } else {
              this.$toasted.global.info({
                message: res.data.details,
              });
            }
          });
      } else {
        this.$toasted.global.info({
          message: "El movimiento debe ser con Cheque",
        });
      }
    },
  },
};
</script>
